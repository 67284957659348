<template>
  <div>
    <b-row>
      <b-col lg="3" md="12" xl="3" class="order-lg-2 order-xl-2">
        <KTPortlet>
          <template v-if="isBusy" v-slot:body>
            <div class="text-center text-danger my-2">
              <b-spinner variant="danger"></b-spinner><br />
              <strong>{{ $t("GENERAL.PLEASE_WAIT") }}</strong>
            </div>
          </template>
          <template v-else v-slot:body>
            <b-row>
              <b-col>
                <b-img
                  v-if="isPhotoLoaded"
                  v-bind="studentImage"
                  rounded
                  :alt="student_profile.name"
                  :src="userPhoto"
                ></b-img>
              </b-col>
              <b-col class="font-weight-bold kt-padding-t-40">
                <h5>
                  <span class="mt-0">{{ student_profile.name + " " }}</span>
                  <span class="mt-0">
                    {{ student_profile.last_name }}
                  </span>
                </h5>
              </b-col>
            </b-row>
            <br />
            <b-row>
              <b-col>
                <label>{{ $t("PROFILE.ID") }}:</label>
              </b-col>
              <b-col>
                <span>{{ student_profile.student_id }}</span>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <label>{{ $t("GENERAL.CURRENT_SEMESTER") }}:</label>
              </b-col>
              <b-col>
                <span>{{ last_semester }}</span>
              </b-col>
            </b-row>
            <br />
            <b-row>
              <b-col>
                <router-link to="/reset-password">
                  <b-button block variant="success">
                    <i class="flaticon-lock"></i>
                    {{ $t("AUTH.FORGOT.RESET_PASSWORD") }}
                  </b-button>
                </router-link>
              </b-col>
            </b-row>
          </template>
        </KTPortlet>
      </b-col>
      <b-col lg="9" md="12" xl="9" class="order-lg-2 order-xl-2">
        <KTPortlet :title="title">
          <template v-if="isBusy" v-slot:body>
            <div class="text-center text-danger my-2">
              <b-spinner variant="danger"></b-spinner><br />
              <strong>{{ $t("GENERAL.PLEASE_WAIT") }}</strong>
            </div>
          </template>
          <template v-else v-slot:body>
            <div role="tablist">
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    v-b-toggle.accordion-1
                    variant="dark"
                    class="font-weight-bold text-left"
                    >{{ $t("PROFILE.PERSONAL_INFORMATION") }}
                  </b-button>
                </b-card-header>
                <b-collapse
                  id="accordion-1"
                  visible
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <b-row>
                        <b-col>
                          <label>{{ $t("PROFILE.NAME") }}:</label>
                        </b-col>
                        <b-col>
                          <span>{{ student_profile.name }}</span>
                        </b-col>
                        <b-col>
                          <label>{{ $t("PROFILE.LAST_NAME") }}:</label>
                        </b-col>
                        <b-col>
                          <span>{{ student_profile.last_name }}</span>
                        </b-col>
                      </b-row>
                      <hr />
                      <b-row>
                        <b-col>
                          <label>{{ $t("PROFILE.FATHER_NAME") }}:</label>
                        </b-col>
                        <b-col>
                          <span>{{ student_profile.father_name }}</span>
                        </b-col>
                        <b-col>
                          <label>{{ $t("PROFILE.GRAND_FATHER_NAME") }}:</label>
                        </b-col>
                        <b-col>
                          <span>{{ student_profile.grand_father_name }}</span>
                        </b-col>
                      </b-row>
                      <hr />
                      <b-row>
                        <b-col>
                          <label>{{ $t("PROFILE.MOTHER_TONGUE") }}:</label>
                        </b-col>
                        <b-col>
                          <span>{{ student_profile.mother_tongue }}</span>
                        </b-col>
                        <b-col>
                          <label>{{ $t("PROFILE.GENDER") }}:</label>
                        </b-col>
                        <b-col>
                          <span v-if="student_profile.gender == 1">ذکور</span>
                          <span v-if="student_profile.gender == 2">اناث</span>
                        </b-col>
                      </b-row>
                      <hr />
                      <b-row>
                        <b-col>
                          <label>{{ $t("PROFILE.PLACE_OF_BIRTH") }}:</label>
                        </b-col>
                        <b-col>
                          <span>{{ student_profile.birth_place }}</span>
                        </b-col>
                        <b-col>
                          <label>{{ $t("PROFILE.DATE_OF_BIRTH") }}:</label>
                        </b-col>
                        <b-col>
                          <span>{{ student_profile.date_of_birth }}</span>
                        </b-col>
                      </b-row>
                      <hr />
                      <b-row>
                        <b-col>
                          <label>{{ $t("PROFILE.JOB") }}:</label>
                        </b-col>
                        <b-col>
                          <span>{{ student_profile.job }}</span>
                        </b-col>
                        <b-col>
                          <label>{{ $t("PROFILE.CONTACT_NUMBER") }}:</label>
                        </b-col>
                        <b-col>
                          <span>{{ student_profile.contact_number }}</span>
                        </b-col>
                      </b-row>
                      <hr />
                      <b-row>
                        <b-col>
                          <label>{{ $t("PROFILE.EMAIL_ADDRESS") }}:</label>
                        </b-col>
                        <b-col>
                          <span>{{ student_profile.email_address }}</span>
                        </b-col>
                        <b-col>
                          <label>{{ $t("PROFILE.BLOOD_GROUP") }}:</label>
                        </b-col>
                        <b-col>
                          <span>{{ student_profile.blood_group }}</span>
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    v-b-toggle.accordion-2
                    variant="dark"
                    class="font-weight-bold text-left"
                    >{{ $t("PROFILE.IDENTITY_CARD_INFORMATION") }}</b-button
                  >
                </b-card-header>
                <b-collapse
                  id="accordion-2"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <b-row>
                        <b-col>
                          <label>{{ $t("PROFILE.TAZKIRA_ID") }}:</label>
                        </b-col>
                        <b-col>
                          <span>{{ student_profile.tazkira_id }}</span>
                        </b-col>
                        <b-col>
                          <label>{{ $t("PROFILE.TAZKIRA_VOLUME") }}:</label>
                        </b-col>
                        <b-col>
                          <span>{{ student_profile.tazkira_volume }}</span>
                        </b-col>
                      </b-row>
                      <hr />
                      <b-row>
                        <b-col>
                          <label>{{ $t("PROFILE.TAZKIRA_PAGE") }}:</label>
                        </b-col>
                        <b-col>
                          <span>{{ student_profile.tazkira_page }}</span>
                        </b-col>
                        <b-col>
                          <label
                            >{{
                              $t("PROFILE.TAZKIRA_REGISTRATION_NUMBER")
                            }}:</label
                          >
                        </b-col>
                        <b-col>
                          <span>{{
                            student_profile.tazkira_registration_number
                          }}</span>
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    v-b-toggle.accordion-3
                    variant="dark"
                    class="font-weight-bold text-left"
                    >{{ $t("PROFILE.ACCOMMODATION_INFORMATION") }}</b-button
                  >
                </b-card-header>
                <b-collapse
                  id="accordion-3"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <b-row>
                        <b-col>
                          <b-alert show variant="dark"
                            >{{ $t("PROFILE.PERMANENT_RESIDENCE") }}:</b-alert
                          >
                        </b-col>
                        <b-col>
                          <b-alert show variant="dark"
                            >{{ $t("PROFILE.CURRENT_RESIDENCE") }}:</b-alert
                          >
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <label
                            >{{ $t("PROFILE.RESIDENTIAL_PROVINCE") }}:</label
                          >
                        </b-col>
                        <b-col>
                          <span>{{ student_profile.permanent_province }}</span>
                        </b-col>
                        <b-col>
                          <label
                            >{{ $t("PROFILE.RESIDENTIAL_PROVINCE") }}:</label
                          >
                        </b-col>
                        <b-col>
                          <span>{{ student_profile.current_province }}</span>
                        </b-col>
                      </b-row>
                      <hr />
                      <b-row>
                        <b-col>
                          <label
                            >{{ $t("PROFILE.RESIDENTIAL_DISTRICT") }}:</label
                          >
                        </b-col>
                        <b-col>
                          <span>{{
                            student_profile.permanent_province_district
                          }}</span>
                        </b-col>
                        <b-col>
                          <label
                            >{{ $t("PROFILE.RESIDENTIAL_DISTRICT") }}:</label
                          >
                        </b-col>
                        <b-col>
                          <span>{{
                            student_profile.current_province_district
                          }}</span>
                        </b-col>
                      </b-row>
                      <hr />
                      <b-row>
                        <b-col>
                          <label
                            >{{ $t("PROFILE.RESIDENTIAL_VILLAGE") }}:</label
                          >
                        </b-col>
                        <b-col>
                          <span>{{
                            student_profile.permanent_residential_village
                          }}</span>
                        </b-col>
                        <b-col>
                          <label
                            >{{ $t("PROFILE.RESIDENTIAL_VILLAGE") }}:</label
                          >
                        </b-col>
                        <b-col>
                          <span>{{
                            student_profile.current_residential_village
                          }}</span>
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    v-b-toggle.accordion-4
                    variant="dark"
                    class="font-weight-bold text-left"
                    >{{ $t("PROFILE.RELATIVES_INFORMATION") }}</b-button
                  >
                </b-card-header>
                <b-collapse
                  id="accordion-4"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <b-row>
                        <b-col>
                          <label>{{ $t("PROFILE.FATHER_JOB") }}:</label>
                        </b-col>
                        <b-col>
                          <span>{{ student_profile.father_job }}</span>
                        </b-col>
                        <b-col>
                          <label
                            >{{ $t("PROFILE.FATHER_CONTACT_NUMBER") }}:</label
                          >
                        </b-col>
                        <b-col>
                          <span>{{
                            student_profile.father_contact_number
                          }}</span>
                        </b-col>
                      </b-row>
                      <hr />
                      <b-row>
                        <b-col>
                          <label>{{ $t("PROFILE.BROTHER_NAME") }}:</label>
                        </b-col>
                        <b-col>
                          <span>{{ student_profile.brother_name }}</span>
                        </b-col>
                        <b-col>
                          <label
                            >{{ $t("PROFILE.BROTHER_CONTACT_NUMBER") }}:</label
                          >
                        </b-col>
                        <b-col>
                          <span>{{
                            student_profile.brother_contact_number
                          }}</span>
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    v-b-toggle.accordion-5
                    variant="dark"
                    class="font-weight-bold text-left"
                    >{{ $t("PROFILE.GUARANTOR_INFORMATION") }}</b-button
                  >
                </b-card-header>
                <b-collapse
                  id="accordion-5"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <b-row>
                        <b-col>
                          <label>{{ $t("PROFILE.NAME") }}:</label>
                        </b-col>
                        <b-col>
                          <span>{{ student_profile.guarantor_name }}</span>
                        </b-col>
                        <b-col>
                          <label>{{ $t("PROFILE.FATHER_NAME") }}:</label>
                        </b-col>
                        <b-col>
                          <span>{{
                            student_profile.guarantor_father_name
                          }}</span>
                        </b-col>
                      </b-row>
                      <hr />
                      <b-row>
                        <b-col>
                          <label>{{ $t("PROFILE.TAZKIRA_ID") }}:</label>
                        </b-col>
                        <b-col>
                          <span>{{
                            student_profile.guarantor_tazkira_id
                          }}</span>
                        </b-col>
                        <b-col>
                          <label>{{ $t("PROFILE.CONTACT_NUMBER") }}:</label>
                        </b-col>
                        <b-col>
                          <span>{{
                            student_profile.guarantor_contact_number
                          }}</span>
                        </b-col>
                      </b-row>
                      <hr />
                      <b-row>
                        <b-col>
                          <label
                            >{{ $t("PROFILE.GUARANTOR_RELATIONSHIP") }}:</label
                          >
                        </b-col>
                        <b-col>
                          <span>{{
                            student_profile.guarantor_relationship
                          }}</span>
                        </b-col>
                        <b-col>
                          <label>{{ $t("PROFILE.JOB") }}:</label>
                        </b-col>
                        <b-col>
                          <span>{{ student_profile.guarantor_job }}</span>
                        </b-col>
                      </b-row>
                      <hr />
                      <b-row>
                        <b-col>
                          <label
                            >{{ $t("PROFILE.GUARANTOR_JOB_LOCATION") }}:</label
                          >
                        </b-col>
                        <b-col>
                          <span>{{
                            student_profile.guarantor_job_location
                          }}</span>
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    v-b-toggle.accordion-6
                    variant="dark"
                    class="font-weight-bold text-left"
                    >{{ $t("PROFILE.KANKOR_INFORMATION") }}</b-button
                  >
                </b-card-header>
                <b-collapse
                  id="accordion-6"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text>
                      <b-row>
                        <b-col>
                          <label>{{ $t("PROFILE.KANKOR_TYPE") }}:</label>
                        </b-col>
                        <b-col>
                          <span>{{ kankor_type }}</span>
                        </b-col>
                        <b-col>
                          <label>{{ $t("PROFILE.KANKOR_PERIOD") }}:</label>
                        </b-col>
                        <b-col>
                          <span>{{ student_profile.education_term_id }}</span>
                        </b-col>
                      </b-row>
                      <hr />
                      <b-row>
                        <b-col>
                          <label>{{ $t("GENERAL.FACULTY") }}:</label>
                        </b-col>
                        <b-col>
                          <span>{{ faculty }}</span>
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
              <b-card no-body class="mb-1">
                <b-card-header header-tag="header" class="p-1" role="tab">
                  <b-button
                    block
                    v-b-toggle.accordion-7
                    variant="dark"
                    class="font-weight-bold text-left"
                    >{{ $t("PROFILE.ACADEMIC_INFORMATION") }}</b-button
                  >
                </b-card-header>
                <b-collapse
                  id="accordion-7"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <b-card-text v-if="student_profile.student_type == 1">
                      <b-row>
                        <b-col>
                          <label>{{ $t("PROFILE.SCHOOL_GRADUATION") }}:</label>
                        </b-col>
                        <b-col>
                          <span>{{ student_profile.school }}</span>
                        </b-col>
                        <b-col>
                          <label
                            >{{ $t("PROFILE.SCHOOL_GRADUATION_YEAR") }}:</label
                          >
                        </b-col>
                        <b-col>
                          <span>{{
                            student_profile.school_graduation_year
                          }}</span>
                        </b-col>
                      </b-row>
                      <div v-if="student_profile.kankor_type == 2">
                        <hr />
                        <b-row>
                          <b-alert show variant="dark"
                            >{{
                              $t("PROFILE.FOURTEENTH_INSTITUTION")
                            }}:</b-alert
                          >
                        </b-row>
                        <b-row>
                          <b-col>
                            <label
                              >{{
                                $t("PROFILE.INSTITUTE_SPECIALIZATION_FIELD")
                              }}:</label
                            >
                          </b-col>
                          <b-col>
                            <span>{{
                              student_profile.institute_specialization_field
                            }}</span>
                          </b-col>
                          <b-col>
                            <label>{{ $t("PROFILE.INSTITUTE_NAME") }}:</label>
                          </b-col>
                          <b-col>
                            <span>{{ student_profile.institute_name }}</span>
                          </b-col>
                        </b-row>
                        <hr />
                        <b-row>
                          <b-col>
                            <label
                              >{{
                                $t("PROFILE.INSTITUTE_MARKS_AVERAGE")
                              }}:</label
                            >
                          </b-col>
                          <b-col>
                            <span>{{
                              student_profile.institute_marks_average
                            }}</span>
                          </b-col>
                          <b-col>
                            <label
                              >{{ $t("PROFILE.INSTITUTE_ADDRESS") }}:</label
                            >
                          </b-col>
                          <b-col>
                            <span>{{ student_profile.institute_address }}</span>
                          </b-col>
                        </b-row>
                        <hr />
                        <b-row>
                          <b-col>
                            <label
                              >{{
                                $t("PROFILE.INSTITUTE_GRADUATION_YEAR")
                              }}:</label
                            >
                          </b-col>
                          <b-col>
                            <span>{{
                              student_profile.institute_graduation_year
                            }}</span>
                          </b-col>
                        </b-row>
                      </div>
                    </b-card-text>
                    <b-card-text v-if="student_profile.student_type == 2">
                      <b-row>
                        <b-alert show variant="dark"
                          >{{ $t("PROFILE.TWELFTH_INSTITUTION") }}:</b-alert
                        >
                      </b-row>
                      <b-row>
                        <b-col>
                          <label>{{ $t("PROFILE.INSTITUTION_NAME") }}:</label>
                        </b-col>
                        <b-col>
                          <span>{{
                            student_profile.twelfth_institution_name
                          }}</span>
                        </b-col>
                        <b-col>
                          <label
                            >{{ $t("PROFILE.INSTITUTION_ADDRESS") }}:</label
                          >
                        </b-col>
                        <b-col>
                          <span>{{
                            student_profile.twelfth_institution_address
                          }}</span>
                        </b-col>
                      </b-row>
                      <hr />
                      <b-row>
                        <b-col>
                          <label
                            >{{
                              $t("PROFILE.INSTITUTION_GRADUATION_YEAR")
                            }}:</label
                          >
                        </b-col>
                        <b-col>
                          <span>{{
                            student_profile.twelfth_institution_graduation_year
                          }}</span>
                        </b-col>
                        <b-col>
                          <label
                            >{{
                              $t("PROFILE.INSTITUTION_MARKS_AVERAGE")
                            }}:</label
                          >
                        </b-col>
                        <b-col>
                          <span>{{
                            student_profile.twelfth_institution_marks_average
                          }}</span>
                        </b-col>
                      </b-row>
                      <hr />
                      <b-row>
                        <b-alert show variant="dark"
                          >{{ $t("PROFILE.FOURTEENTH_INSTITUTION") }}:</b-alert
                        >
                      </b-row>
                      <b-row>
                        <b-col>
                          <label>{{ $t("PROFILE.INSTITUTION_NAME") }}:</label>
                        </b-col>
                        <b-col>
                          <span>{{
                            student_profile.fourteenth_institution_name
                          }}</span>
                        </b-col>
                        <b-col>
                          <label
                            >{{ $t("PROFILE.INSTITUTION_FACULTY") }}:</label
                          >
                        </b-col>
                        <b-col>
                          <span>{{
                            student_profile.fourteenth_institution_faculty
                          }}</span>
                        </b-col>
                      </b-row>
                      <hr />
                      <b-row>
                        <b-col>
                          <label
                            >{{ $t("PROFILE.INSTITUTION_DEPARTMENT") }}:</label
                          >
                        </b-col>
                        <b-col>
                          <span>{{
                            student_profile.fourteenth_institution_department
                          }}</span>
                        </b-col>
                        <b-col>
                          <label
                            >{{ $t("PROFILE.INSTITUTION_ADDRESS") }}:</label
                          >
                        </b-col>
                        <b-col>
                          <span>{{
                            student_profile.fourteenth_institution_address
                          }}</span>
                        </b-col>
                      </b-row>
                      <hr />
                      <b-row>
                        <b-col>
                          <label
                            >{{
                              $t("PROFILE.INSTITUTION_GRADUATION_YEAR")
                            }}:</label
                          >
                        </b-col>
                        <b-col>
                          <span>{{
                            student_profile.fourteenth_institution_department
                          }}</span>
                        </b-col>
                        <b-col>
                          <label
                            >{{
                              $t("PROFILE.INSTITUTION_MARKS_AVERAGE")
                            }}:</label
                          >
                        </b-col>
                        <b-col>
                          <span>{{
                            student_profile.fourteenth_institution_address
                          }}</span>
                        </b-col>
                      </b-row>
                      <hr />
                      <b-row>
                        <b-alert show variant="dark"
                          >{{ $t("PROFILE.BACHELOR_INSTITUTION") }}:</b-alert
                        >
                      </b-row>
                      <b-row>
                        <b-col>
                          <label>{{ $t("PROFILE.INSTITUTION_NAME") }}:</label>
                        </b-col>
                        <b-col>
                          <span>{{
                            student_profile.bachelor_institution_name
                          }}</span>
                        </b-col>
                        <b-col>
                          <label
                            >{{ $t("PROFILE.INSTITUTION_FACULTY") }}:</label
                          >
                        </b-col>
                        <b-col>
                          <span>{{
                            student_profile.bachelor_institution_faculty
                          }}</span>
                        </b-col>
                      </b-row>
                      <hr />
                      <b-row>
                        <b-col>
                          <label
                            >{{ $t("PROFILE.INSTITUTION_DEPARTMENT") }}:</label
                          >
                        </b-col>
                        <b-col>
                          <span>{{
                            student_profile.bachelor_institution_department
                          }}</span>
                        </b-col>
                        <b-col>
                          <label
                            >{{ $t("PROFILE.INSTITUTION_ADDRESS") }}:</label
                          >
                        </b-col>
                        <b-col>
                          <span>{{
                            student_profile.bachelor_institution_address
                          }}</span>
                        </b-col>
                      </b-row>
                      <hr />
                      <b-row>
                        <b-col>
                          <label
                            >{{
                              $t("PROFILE.INSTITUTION_GRADUATION_YEAR")
                            }}:</label
                          >
                        </b-col>
                        <b-col>
                          <span>{{
                            student_profile.bachelor_institution_graduation_year
                          }}</span>
                        </b-col>
                        <b-col>
                          <label
                            >{{
                              $t("PROFILE.INSTITUTION_MARKS_AVERAGE")
                            }}:</label
                          >
                        </b-col>
                        <b-col>
                          <span>{{
                            student_profile.bachelor_institution_marks_average
                          }}</span>
                        </b-col>
                      </b-row>
                    </b-card-text>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </template>
        </KTPortlet>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12" md="12" xl="12" class="order-lg-1 order-xl-1">
        <KTPortlet :title="$t('PROFILE.STUDENT_FREEZE_INFORMATION')">
          <template v-slot:body>
            <b-table
              :items="student_freezes"
              :fields="fields"
              :busy="isBusy"
              striped
              hover
              show-empty
              head-variant="dark"
            >
              <template v-slot:empty="scope">
                <p class="text-center">
                  {{ $t("PROFILE.NO_FREEZE") }}
                </p>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong>{{ $t("GENERAL.PLEASE_WAIT") }}</strong>
                </div>
              </template>
            </b-table>
          </template>
        </KTPortlet>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import ApiService from "@/common/api.service";
import { mapState } from "vuex";
import { getUserPhoto } from "@/common/jwt.service";

export default {
  data() {
    return {
      isBusy: true,
      title: this.$t("PROFILE.GENERAL_INFORMATION"),
      fields: [
        { freeze_year: this.$t("PROFILE.EDUCATIONAL_YEAR") },
        { freeze_semester: this.$t("GENERAL.SEMESTER") },
        { freeze_term: this.$t("PROFILE.EDUCATIONAL_TERM") },
        { freeze_detail: this.$t("PROFILE.REASON") },
        { freeze_state: this.$t("PROFILE.CURRENT_STATE") },
        { freeze_date: this.$t("PROFILE.START_DATE") },
        { unfreeze_date: this.$t("PROFILE.END_DATE") }
      ],
      student_profile: [],
      student_freezes: [],
      last_semester: "",
      faculty: "",
      kankor_type: "",
      studentImage: { width: 100, height: 100 }
    };
  },
  components: {
    KTPortlet
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$t("MENU.PROFILE"),
        route: this.$route
      },
      { title: "" }
    ]);
    ApiService.get("student/profile").then(({ data }) => {
      this.student_profile = data.student_profile;
      this.last_semester = data.last_semester;
      this.faculty = data.faculty;
      this.student_freezes = data.student_freezes;
      this.kankor_type =
        this.student_profile.kankor_type === 1
          ? "کانکور عمومی"
          : this.student_profile.kankor_type === 2
          ? "کانکور اختصاصی"
          : this.student_profile.kankor_type === 3
          ? "کانکور دولتی"
          : "کانکور متفرقه";
      this.isBusy = false;
    });
  },
  computed: {
    ...mapState({
      isPhotoLoaded: state => state.auth.isPhotoLoaded
    }),
    userPhoto() {
      if (this.isPhotoLoaded) {
        return getUserPhoto();
      }
      return null;
    }
  }
};
</script>
